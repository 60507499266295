<template>
  <base-page>
    <Filters model="entities" color="reseau" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { Filters } from '../../components/filters'

export default {
  name: 'EntitiesPage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Réseau | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['entities/getFilters']) {
      store.dispatch('entities/fetchFilters')
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])

    this.$store.commit('pages/setPageTitle', this.$t('entities.entities_list'))

    this.$store.commit('pages/setTabs', [
      { name: 'list', label: 'Liste', to: { name: 'entities-list' } },
      { name: 'map', label: 'Carte', to: { name: 'entities-map' } }
    ])
  }
}
</script>
